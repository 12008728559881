import { useEffect } from 'react';
import { scrollToElement } from '@utils/dom/scrollToElement';
import type { CountryCode } from '@utils/types/countries';
import type { DataPeriod } from '@utils/types/dataPeriod';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { DEFAULT_N_DAYS_RANGE_IN_10MN, initEndDate, initStartDate } from '../constants';
import type { ProductionFilters } from '../Production';
import type { DateRange } from '../production.types';

export const useFiltersForm = (isMarketPlayer: boolean, countries: CountryCode[]) => {
  const filtersForm = useForm<ProductionFilters>({
    defaultValues: {
      dataPeriod: isMarketPlayer ? 'monthly' : 'minutes',
      dates: isMarketPlayer ? getDatesForPeriod('monthly', initEndDate) : [initStartDate, initEndDate],
      showReactivePowerMonitoring: false,
      showProductionMinMaxAverage: false,
      selectedMarketPriceCurve: countries.includes('UK')
        ? 'UK_NORDPOOL'
        : countries.includes('IT')
        ? 'IT_IPEX'
        : `${countries[0]}_ENTSOE`,
    },
  });
  const { watch, setValue } = filtersForm;

  useEffect(() => {
    const { unsubscribe } = watch((values, { name }) => {
      if (name === 'dataPeriod' && values['dataPeriod'] && values['dates']?.[1]) {
        setValue('dates', getDatesForPeriod(values['dataPeriod'], values['dates'][1]));
      }

      if (name === 'showReactivePowerMonitoring' && values['showReactivePowerMonitoring']) {
        scrollToElement('.page-content');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [watch, setValue]);

  return filtersForm;
};

const getDatesForPeriod = (dataPeriod: DataPeriod, endDate: Date): DateRange => {
  const defaultStartDates = {
    minutes: dayjs(endDate).subtract(DEFAULT_N_DAYS_RANGE_IN_10MN, 'day').toDate(),
    daily: dayjs(endDate).subtract(1, 'month').toDate(),
    // Have to add an hour to get the correct month from the back end, it very much is possible
    // that it is a timezone synchronization issue.
    monthly: dayjs(endDate).subtract(1, 'year').startOf('month').add(1, 'hour').toDate(),
  };

  const startDate = defaultStartDates[dataPeriod];

  return [startDate, endDate];
};
