/**
 * THIS FILE IS AUTO_GENERATED DO NOT UPDATE IT
 * RUN THE FOLLOWING COMMAND TO GENERATE IT : rails runner "./scripts/front_static/generate_contract_sub_types.rb"
 */

export const contractOaSubTypes = [
  'Photo_2006_V1',
  'Photo_2006_V3',
  'Photo_2010_V1',
  'Photo_2011M_V1',
  'Photo_2011M_V2',
  'Photo_2011S_V1',
  'Photo_2011_V1',
  'FV11_SF1_V1',
  'FV11_SF1_V2',
  'FV11_SF2',
  'FV11_SF3_V2',
  'FV11_SF4_V2',
  'FV11_SF5_V1',
  'FV11_SF6',
  'FV11_SF6_V1',
  'FV11_SF6_V2',
  'FV11_SF7_V2',
  'FS15',
  'FS11',
  'FS13',
  'FV13_V1',
  'FV14_V1',
  'FV16SCR_V01',
  'FV16BOA_V01',
  'FV16BOA_V02',
  'S17-V2',
  'FV16SCR_V2.0.0',
  'FSI_17_CR',
  'FSI_17_OA',
  'S21_V1',
  'S21_V2',
  'CG_97-07',
  'EOL03-01',
  'E06-V00',
  'E06-V01',
  'E08-V00',
  'E08-V01',
  'E08-V0',
  'E14-V0',
  'E14-V1',
  'E16-V1',
  'FET17_CR_V1',
  'H07_V01',
  'H16_OA',
] as const;

export const contractCrEdfSolarSubTypes = [
  'FV16SCR_V01',
  'FV16SCR_V2.0.1',
  'FV16SCR_V2.0.0',
  'FSI_17_CR',
  'FSE17CR',
  'FSE21CR',
] as const;

export const contractCrEdfWindSubTypes = [
  'cr17',
  'cr16',
  'E16-V1',
  'E17_V1.0.1',
  'E17_V1.0.2',
  'E17_V2',
  'FET17_CR_V1',
  'FET21_CR_V1',
] as const;

export const contractCrEdfHydroSubTypes = ['H16_CR'] as const;

export type ContractSubType =
  | typeof contractOaSubTypes[number]
  | typeof contractCrEdfSolarSubTypes[number]
  | typeof contractCrEdfWindSubTypes[number]
  | typeof contractCrEdfHydroSubTypes[number];
