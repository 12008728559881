// import { NESTED_DATA_SAMPLE } from './data';
import {
  GreenstarFocusData,
  GreenstarReportData,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
} from './types';

export type RiskAnalysisChart = {
  key: string;
  series: Highcharts.Options['series'];
  options: Highcharts.Options;
};

const SENSITIVITY_VOLUME_METRICS = ['total'];
const VALUATION_VOLUME_METRICS = ['Volume', 'Open Volume', 'Open Value', 'Clicked Volume'];
const VOLUME_METRICS = [...VALUATION_VOLUME_METRICS, ...SENSITIVITY_VOLUME_METRICS];
const LINE_METRICS = ['HCR'];
const ALL_CHART_METRICS = [...VOLUME_METRICS, ...LINE_METRICS];

export const useRiskAnalysisCharts = ({
  data,
  isReportNested,
  splitByMetric,
}: {
  data: GreenstarReportData;
  isReportNested: boolean;
  splitByMetric: boolean;
}): RiskAnalysisChart[] => {
  const charts: RiskAnalysisChart[] = [];

  if (!isReportNested && !splitByMetric) {
    Object.entries(data).forEach(([focusValue, dateIndexedData]: [string, GreenstarFocusData]) => {
      const dates = Object.keys(dateIndexedData).sort();
      const seriesMap = new Map<string, number[]>();
      dates.forEach((date) => {
        Object.entries(dateIndexedData[date]).forEach(([metric, value]) => {
          if (!ALL_CHART_METRICS.includes(metric)) return;

          let serie = seriesMap.get(metric);
          if (!serie) {
            serie = [];
            seriesMap.set(metric, serie);
          }

          serie.push(Number(value));
        });
      });

      const series = [...seriesMap.entries()]
        .map(([name, data]) => ({
          name,
          data,
          yAxis: Number(VOLUME_METRICS.includes(name)),
          type: VOLUME_METRICS.includes(name) ? 'column' : 'line',
          tooltip: { valueSuffix: VOLUME_METRICS.includes(name) ? 'kWh' : '%' },
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) as Highcharts.Options['series'];

      charts.push({
        series,
        key: focusValue,
        options: {
          title: { text: focusValue, align: 'left' },
          xAxis: {
            type: 'category',
            categories: dates,
          },
          yAxis: [
            {
              title: { text: null },
              min: 0,
              max: 100,
              labels: { format: '{value} %' },
            },
            {
              title: { text: null },
              opposite: true,
            },
          ],
          plotOptions: {
            column: {
              minPointLength: 3,
              stacking: 'normal',
            },
          },
        },
      });
    });
  } else if (isReportNested && !splitByMetric) {
    Object.entries(data).forEach(([focusValue, groupOfDateIndexedData]: [string, GreenstarReportDataWithOneFocus]) => {
      const dateIndexedDataList = Object.values(groupOfDateIndexedData);

      const dates = Object.keys(
        dateIndexedDataList.reduce((acc, value) => ({ ...acc, ...value }), {} as GreenstarFocusData),
      ).sort();

      const valuesList: GreenstarReportMetricValues[] = [];

      dates.forEach((date) => {
        const values: GreenstarReportMetricValues = {};

        dateIndexedDataList.forEach((dateIndexedData) => {
          if (!dateIndexedData[date]) return;
          Object.entries(dateIndexedData[date]).forEach(([metric, value]) => {
            values[metric] = Number(values[metric] || 0) + Number(value);
          });
        });

        valuesList.push(values);
      });

      console.log(focusValue, valuesList, 'valuesList', dates);
      const seriesMap = new Map<string, number[]>();
      const hcrSerie: (number | null)[] = [];
      seriesMap.set('HCR', hcrSerie as number[]);

      valuesList.forEach((values) => {
        Object.entries(values).forEach(([metric, value]) => {
          // We'll compute HCR ourselves
          if (!ALL_CHART_METRICS.includes(metric) || metric == 'HCR') return;

          let serie = seriesMap.get(metric);
          if (!serie) {
            serie = [];
            seriesMap.set(metric, serie);
          }

          serie.push(Number(value));
        });
        const isClickedVolumeDefined = typeof values['Clicked Volume'] !== 'undefined';
        const isVolumeDefined = typeof values['Volume'] !== 'undefined';
        const isHcrComputable = isClickedVolumeDefined && isVolumeDefined;
        const hcr = isHcrComputable ? (100 * Number(values['Clicked Volume'])) / Number(values['Volume']) : null;
        hcrSerie.push(hcr);
      });

      if (hcrSerie.every((hcr) => hcr === null)) {
        seriesMap.delete('HCR');
      }
      const series = [...seriesMap.entries()]
        .map(([name, data]) => ({
          name,
          data,
          yAxis: Number(VOLUME_METRICS.includes(name)),
          type: VOLUME_METRICS.includes(name) ? 'column' : 'line',
          tooltip: { valueSuffix: VOLUME_METRICS.includes(name) ? 'kWh' : '%' },
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) as Highcharts.Options['series'];

      charts.push({
        series,
        key: focusValue,
        options: {
          title: { text: focusValue, align: 'left' },
          xAxis: {
            type: 'category',
            categories: dates,
          },
          yAxis: [
            {
              title: { text: null },
              min: 0,
              max: 100,
              labels: { format: '{value} %' },
            },
            {
              title: { text: null },
              opposite: true,
            },
          ],
          plotOptions: {
            column: {
              minPointLength: 3,
              stacking: 'normal',
            },
          },
        },
      });
    });
  }

  console.log(charts);

  return charts;
};
