import React, { useMemo } from 'react';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { Button } from '@GDM/Button/Button';
import { Chart } from '@GDM/Chart/Chart';
import { Icon } from '@GDM/Icon';
import { NumberCell } from '@GDM/Table';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import {
  AccessorKeyColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { UseFormReturn, useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import {
  GreenstarFocusData,
  GreenstarReport,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
  RiskAnalysisReportType,
} from './types';
import { useRiskAnalysisCharts } from './useCharts';

type GreenstarRow = {
  name: string;
  subRows: GreenstarRow[];
  values: GreenstarReportMetricValues;
};

export const RiskAnalysisReport = ({
  report: { data, filters, exportUrl },
  type,
}: {
  report: GreenstarReport;
  type: RiskAnalysisReportType;
}) => {
  const isReportNested = Boolean(filters.secondaryFocus) || type === 'sensitivity';

  const { columns, rows } = useMemo(() => {
    const dateSet = new Set<string>();
    const rows: GreenstarRow[] = [];

    Object.entries(data).forEach(
      ([focus1, focusDataOrMetricValuesForEachDates]: [
        string,
        GreenstarFocusData | GreenstarReportDataWithOneFocus,
      ]) => {
        const row: GreenstarRow = { name: focus1, subRows: [], values: {} };
        rows.push(row);
        const metricRows: Record<string, GreenstarRow> = {};

        Object.entries(focusDataOrMetricValuesForEachDates).forEach(
          ([dateOrSecondaryFocus, metricValuesOrFocusData]: [
            string,
            GreenstarReportMetricValues | GreenstarFocusData,
          ]) => {
            if (!isReportNested) {
              dateSet.add(dateOrSecondaryFocus);
              row.values[dateOrSecondaryFocus] = '';

              Object.entries(metricValuesOrFocusData).forEach(([metric, value]) => {
                metricRows[metric] = {
                  ...metricRows[metric],
                  name: metric,
                  subRows: [],
                  values: {
                    ...metricRows[metric]?.values,
                    [dateOrSecondaryFocus]: value,
                  },
                };
              });

              return;
            }

            const subRow: GreenstarRow = { name: dateOrSecondaryFocus, subRows: [], values: {} };
            row.subRows.push(subRow);

            Object.entries(metricValuesOrFocusData).forEach(([date, values]: [string, GreenstarReportMetricValues]) => {
              dateSet.add(date);
              row.values[date] = '';
              subRow.values[date] = '';
              Object.entries(values).forEach(([metric, value]) => {
                metricRows[metric] = {
                  ...metricRows[metric],
                  name: metric,
                  subRows: [],
                  values: {
                    ...metricRows[metric]?.values,
                    [date]: value,
                  },
                };
              });
            });

            subRow.subRows.push(...Object.values(metricRows));
          },
        );

        if (!isReportNested) row.subRows.push(...Object.values(metricRows));
      },
    );

    const dates = [...dateSet.values()].sort();
    const columns: AccessorKeyColumnDef<GreenstarRow>[] = [
      {
        id: 'name',
        header: '',
        accessorKey: 'name',
        cell: ({ row, getValue }) => (
          <div
            className={classNames({
              [styles['leaf-row']]: isReportNested ? row.depth === 2 : row.depth === 1,
              'pl-4': isReportNested && row.depth === 1,
            })}
          >
            {(isReportNested ? row.depth < 2 : row.depth === 0) && (
              <Icon
                name={row.getIsExpanded() ? 'ChevronDown' : 'ChevronRight'}
                className="cursor-pointer select-none"
                size={14}
                onClick={() => {
                  row.toggleExpanded();
                }}
              />
            )}{' '}
            {getValue<string>()}
          </div>
        ),
      },
      ...(dates.map<AccessorKeyColumnDef<GreenstarRow>>((dateKey) => {
        return {
          id: dateKey,
          header: dateKey,
          cell: ({ getValue }) => (
            <NumberCell
              value={getValue()}
              // fractionDigits={FRACTION_DIGITS_PER_METRIC[selectedData.metric]}
            />
          ),
          accessorKey: `values.${dateKey}`,
        };
      }) || []),
    ];

    return { columns, rows };
  }, [data, isReportNested]);

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { control, watch } = useForm();
  const display = watch('display', 'table');
  const splitByMetric = watch('splitByMetric', false);
  const charts = useRiskAnalysisCharts({ data, isReportNested, splitByMetric });

  return (
    <div className="mt-4">
      {type === 'sensitivity' && <RiskAnalysisSensitivityReportHeader control={control} exportUrl={exportUrl} />}
      {type === 'valuation' && <RiskAnalysisValuationReportHeader control={control} exportUrl={exportUrl} />}
      <div className="mt-4">
        {display === 'table' && (
          <Table>
            <TableHead table={table} />
            <TableBody table={table} />
          </Table>
        )}
        {display === 'chart' && (
          <div className={styles['chart-grid']}>
            {charts.map((chart) => (
              <Chart {...chart} key={chart.key} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const RiskAnalysisValuationReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: UseFormReturn['control'];
}) => {
  return (
    <form className="d-flex justify-content-between">
      <div>
        <ControlledCheckbox className="mr-2" name="volume" label="Volume" control={control} defaultValue />
        <ControlledCheckbox
          className="mr-2"
          name="signed_volume"
          label="Signed Volume"
          control={control}
          defaultValue
        />
        <ControlledCheckbox
          className="mr-2"
          name="signed_hedged_volume"
          label="Signed Hedged Volume"
          control={control}
          defaultValue
        />
        <ControlledCheckbox className="mr-2" name="hcr" label="HCR" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="mtom" label="MtoM" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="price" label="Price" control={control} defaultValue />
        <ControlledToggle name="splitByMetric" label="Split by Metric" control={control} />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />
        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
          onClick={debounce(() => {
            window.location.href = exportUrl;
          })}
        />
      </div>
    </form>
  );
};

const RiskAnalysisSensitivityReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: UseFormReturn['control'];
}) => {
  return (
    <form className="d-flex justify-content-between">
      <div>
        <ControlledCheckbox
          className="mr-2"
          name="price_outright"
          label="Price Outright"
          control={control}
          defaultValue
        />
        <ControlledCheckbox className="mr-2" name="profile_risk" label="Profile Risk" control={control} defaultValue />
        <ControlledCheckbox
          className="mr-2"
          name="balancing_risk"
          label="Balancing Risk"
          control={control}
          defaultValue
        />
        <ControlledToggle name="splitByMetric" label="Split by Metric" control={control} />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />

        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
          onClick={debounce(() => {
            window.location.href = exportUrl;
          })}
        />
      </div>
    </form>
  );
};
