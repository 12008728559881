import { GreenstarReportData } from './types';

export const DATA_SAMPLE = {
  OA: {
    '2024': {
      Volume: 1349271,
      MtoM: 101692300,
    },
    '2025': {
      Volume: 2838568,
      MtoM: 213929980,
    },
    '2026': {
      Volume: 2210966,
      MtoM: 166635906,
    },
    '2027': {
      Volume: 2061683,
      MtoM: 155386184,
    },
    '2028': {
      Volume: 1862576,
      MtoM: 140381269,
    },
    '2029': {
      Volume: 1796621,
      MtoM: 135409183,
    },
  },
  PPA: {
    '2024': {
      Volume: 114110,
      MtoM: 8600470,
    },
    '2025': {
      Volume: 501478,
      MtoM: 37795339,
    },
    '2026': {
      Volume: 504509,
      MtoM: 38024030,
    },
    '2027': {
      Volume: 504662,
      MtoM: 38035296,
    },
    '2028': {
      Volume: 499811,
      MtoM: 37669511,
    },
    '2029': {
      Volume: 491958,
      MtoM: 37078251,
    },
  },
  SOA: {
    '2024': {
      Volume: 346339,
      MtoM: 26104046,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 346339,
      HCR: 100,
    },
    '2025': {
      Volume: 993150,
      MtoM: 74850881,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 993150,
      HCR: 100,
    },
    '2026': {
      Volume: 1646490,
      MtoM: 124083799,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 1646490,
      HCR: 100,
    },
    '2027': {
      Volume: 1740656,
      MtoM: 131179709,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 1740656,
      HCR: 100,
    },
    '2028': {
      Volume: 1449368,
      MtoM: 109228118,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 1449368,
      HCR: 100,
    },
    '2029': {
      Volume: 1447530,
      MtoM: 109090399,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 1447530,
      HCR: 100,
    },
  },
  CR: {
    '2024': {
      Volume: 575887,
      MtoM: 43405446,
    },
    '2025': {
      Volume: 1989216,
      MtoM: 149922675,
    },
    '2026': {
      Volume: 2376589,
      MtoM: 179114269,
    },
    '2027': {
      Volume: 2464253,
      MtoM: 185721745,
    },
    '2028': {
      Volume: 2461428,
      MtoM: 185509543,
    },
    '2029': {
      Volume: 2438451,
      MtoM: 183778238,
    },
  },
  DCR: {
    '2024': {
      Volume: 360047,
      MtoM: 27134782,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 360047,
      HCR: 100,
    },
    '2025': {
      Volume: 329370,
      MtoM: 24822418,
      'Open Volume': 0,
      'Open Value': 0,
      'Clicked Volume': 329370,
      HCR: 100,
    },
    '2026': {
      Volume: 106391,
      MtoM: 8018636,
      'Open Volume': 26132,
      'Open Value': 1969222,
      'Clicked Volume': 80259,
      HCR: 75,
    },
    '2027': {
      Volume: 63325,
      MtoM: 4772136,
      'Open Volume': 25866,
      'Open Value': 1949128,
      'Clicked Volume': 37459,
      HCR: 59,
    },
    '2028': {
      Volume: 62665,
      MtoM: 4722676,
      'Open Volume': 25597,
      'Open Value': 1928987,
      'Clicked Volume': 37068,
      HCR: 59,
    },
    '2029': {
      Volume: 62011,
      MtoM: 4673319,
      'Open Volume': 25331,
      'Open Value': 1908887,
      'Clicked Volume': 36680,
      HCR: 59,
    },
  },
};

export const NESTED_DATA_SAMPLE: GreenstarReportData = {
  OA: {
    wind: {
      '2024': {
        Volume: 1110326,
        MtoM: 83677602,
      },
      '2025': {
        Volume: 2260003,
        MtoM: 170316033,
      },
      '2026': {
        Volume: 1638290,
        MtoM: 123465329,
      },
      '2027': {
        Volume: 1494899,
        MtoM: 112658983,
      },
      '2028': {
        Volume: 1301691,
        MtoM: 98098514,
      },
      '2029': {
        Volume: 1241617,
        MtoM: 93569898,
      },
    },
    solar: {
      '2024': {
        Volume: 238945,
        MtoM: 18014698,
      },
      '2025': {
        Volume: 578565,
        MtoM: 43613947,
      },
      '2026': {
        Volume: 572676,
        MtoM: 43170577,
      },
      '2027': {
        Volume: 566784,
        MtoM: 42727201,
      },
      '2028': {
        Volume: 560885,
        MtoM: 42282755,
      },
      '2029': {
        Volume: 555004,
        MtoM: 41839285,
      },
    },
  },
  PPA: {
    solar: {
      '2024': {
        Volume: 81522,
        MtoM: 6144295,
      },
      '2025': {
        Volume: 372984,
        MtoM: 28111422,
      },
      '2026': {
        Volume: 376015,
        MtoM: 28340113,
      },
      '2027': {
        Volume: 376168,
        MtoM: 28351379,
      },
      '2028': {
        Volume: 371104,
        MtoM: 27969647,
      },
      '2029': {
        Volume: 369385,
        MtoM: 27840305,
      },
    },
    wind: {
      '2024': {
        Volume: 32588,
        MtoM: 2456175,
      },
      '2025': {
        Volume: 128494,
        MtoM: 9683917,
      },
      '2026': {
        Volume: 128494,
        MtoM: 9683917,
      },
      '2027': {
        Volume: 128494,
        MtoM: 9683917,
      },
      '2028': {
        Volume: 128707,
        MtoM: 9699864,
      },
      '2029': {
        Volume: 122573,
        MtoM: 9237946,
      },
    },
  },
  SOA: {
    wind: {
      '2024': {
        Volume: 346339,
        MtoM: 26104046,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 346339,
        HCR: 100,
      },
      '2025': {
        Volume: 993150,
        MtoM: 74850881,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 993150,
        HCR: 100,
      },
      '2026': {
        Volume: 1646490,
        MtoM: 124083799,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 1646490,
        HCR: 100,
      },
      '2027': {
        Volume: 1740656,
        MtoM: 131179709,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 1740656,
        HCR: 100,
      },
      '2028': {
        Volume: 1449368,
        MtoM: 109228118,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 1449368,
        HCR: 100,
      },
      '2029': {
        Volume: 1447530,
        MtoM: 109090399,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 1447530,
        HCR: 100,
      },
    },
  },
  CR: {
    solar: {
      '2024': {
        Volume: 191857,
        MtoM: 14464594,
      },
      '2025': {
        Volume: 715789,
        MtoM: 53957886,
      },
      '2026': {
        Volume: 803801,
        MtoM: 60590431,
      },
      '2027': {
        Volume: 836404,
        MtoM: 63049238,
      },
      '2028': {
        Volume: 832927,
        MtoM: 62787664,
      },
      '2029': {
        Volume: 810602,
        MtoM: 61105731,
      },
    },
    wind: {
      '2024': {
        Volume: 384030,
        MtoM: 28940852,
      },
      '2025': {
        Volume: 1273427,
        MtoM: 95964789,
      },
      '2026': {
        Volume: 1572788,
        MtoM: 118523838,
      },
      '2027': {
        Volume: 1627849,
        MtoM: 122672507,
      },
      '2028': {
        Volume: 1628501,
        MtoM: 122721879,
      },
      '2029': {
        Volume: 1627849,
        MtoM: 122672507,
      },
    },
  },
  DCR: {
    solar: {
      '2024': {
        Volume: 155799,
        MtoM: 11742827,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 155799,
        HCR: 100,
      },
      '2025': {
        Volume: 162366,
        MtoM: 12237913,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 162366,
        HCR: 100,
      },
      '2026': {
        Volume: 106391,
        MtoM: 8018636,
        'Open Volume': 26132,
        'Open Value': 1969222,
        'Clicked Volume': 80259,
        HCR: 75,
      },
      '2027': {
        Volume: 63325,
        MtoM: 4772136,
        'Open Volume': 25866,
        'Open Value': 1949128,
        'Clicked Volume': 37459,
        HCR: 59,
      },
      '2028': {
        Volume: 62665,
        MtoM: 4722676,
        'Open Volume': 25597,
        'Open Value': 1928987,
        'Clicked Volume': 37068,
        HCR: 59,
      },
      '2029': {
        Volume: 62011,
        MtoM: 4673319,
        'Open Volume': 25331,
        'Open Value': 1908887,
        'Clicked Volume': 36680,
        HCR: 59,
      },
    },
    wind: {
      '2024': {
        Volume: 204248,
        MtoM: 15391955,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 204248,
        HCR: 100,
      },
      '2025': {
        Volume: 167004,
        MtoM: 12584505,
        'Open Volume': 0,
        'Open Value': 0,
        'Clicked Volume': 167004,
        HCR: 100,
      },
    },
  },
};
