import React, { useEffect, useMemo } from 'react';
import { DatePickerProps } from '@GDM/DatePicker';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { ContractForm, ContractType } from '@utils/types/contract';
import { SPOT_SOURCES, IT_PRICE_AREAS } from '@utils/types/contract/constants/spot-sources';
import { CountryCode } from '@utils/types/countries';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../../../../hooks/useDateValidation';
import { PRICE_TYPE_CONTRACT_OPTIONS_MAP } from '../../../constants/priceTypes';
import styles from '../../../sub-period.module.scss';

export const DatesAndPriceType = ({
  hedgeProfileMode,
  index,
  form: { control, watch, setValue },
  country,
  selectedPeriod,
  customPeriods,
  onClickPeriod,
  readOnly,
  contractType,
  datesValidation,
}: {
  index: number;
  hedgeProfileMode?: boolean;
  form: UseFormReturn<ContractForm>;
  country?: CountryCode | null;
  readOnly: boolean;
  contractType: ContractType;
  datesValidation: DateValidators;
} & Pick<DatePickerProps, 'selectedPeriod' | 'customPeriods' | 'onClickPeriod'>) => {
  const priceType = watch(`contract_sub_periods_attributes.${index}.price_type`);
  const priceSource = watch(`contract_sub_periods_attributes.${index}.price_model_attributes.source`);

  const priceTypeOptions = PRICE_TYPE_CONTRACT_OPTIONS_MAP[contractType] || [];

  const spotAreaOptions = useMemo(() => {
    if (country !== 'IT') return [];
    const areas = IT_PRICE_AREAS;

    return (
      areas.map((area) => ({
        label: area.replace('_', ' '),
        value: area,
      })) || []
    );
  }, [country]);

  const spotSourceOptions = useMemo(() => {
    if (!country) return [];
    const sources = SPOT_SOURCES as Partial<Record<CountryCode, typeof SPOT_SOURCES[keyof typeof SPOT_SOURCES]>>;

    return (
      sources[country]?.map((source) => {
        let label: string = source.toUpperCase();
        if (source === 'ipex') label = 'PUN';

        return {
          label,
          value: source,
        };
      }) || []
    );
  }, [country]);

  useEffect(() => {
    if (priceType === 'spot' && spotSourceOptions?.length === 1)
      setValue(`contract_sub_periods_attributes.${index}.price_model_attributes.source`, spotSourceOptions[0].value);
  }, [country, index, setValue, spotSourceOptions, priceType]);

  return (
    <div className={styles.section}>
      <div className={styles['row-3']}>
        <DateInput
          id="SubPeriodDatesPicker"
          name={`contract_sub_periods_attributes.${index}.dates`}
          control={control}
          rules={{ validate: datesValidation }}
          data-cy={`dates-picker-${index}`}
          selectedPeriod={selectedPeriod}
          selectsRange
          showPeriodBadge
          customPeriods={customPeriods}
          onClickPeriod={onClickPeriod}
          readOnly={readOnly}
          label="sales_management.sub_period.start_end_dates"
        />
        <ListInput
          id="PriceType"
          control={control}
          options={priceTypeOptions}
          name={`contract_sub_periods_attributes.${index}.price_type`}
          label="common.price_type"
          rules={{ required: true }}
          menuPlacement="top"
          readOnly={readOnly}
          classNamePrefix={`select-price-type-${index}`}
        />

        {priceType === 'spot' && spotSourceOptions?.length > 1 && (
          <ListInput
            id="SourcePicker"
            control={control}
            options={spotSourceOptions}
            rules={{ required: true }}
            defaultValue={spotSourceOptions[0].value}
            label="sales_management.price_model.source"
            name={`contract_sub_periods_attributes.${index}.price_model_attributes.source`}
            helpBox={{
              title: 'sales_management.price_model.source',
              text: 'sales_management.price_model.source_info',
            }}
            tooltip={!country ? 'sales_management.price_model.source_needs_area' : null}
            classNamePrefix={`select-price-model-source-${index}`}
          />
        )}

        {priceType === 'spot' && priceSource == 'epex' && spotAreaOptions.length > 0 && (
          <ListInput
            id="PriceAreaPicker"
            control={control}
            options={spotAreaOptions}
            rules={{ required: true }}
            defaultValue={spotAreaOptions[0].value}
            label="sales_management.price_model.area"
            name={`contract_sub_periods_attributes.${index}.price_model_attributes.area`}
            classNamePrefix={`select-price-model-area-${index}`}
          />
        )}

        {hedgeProfileMode && (
          <ListInput
            id="VolumeTypePicker"
            label="sales_management.volume_type"
            size="lg"
            name={`contract_sub_periods_attributes.${index}.volume_type`}
            control={control}
            options={[
              { value: 'full', label: 'sales_management.whole' },
              { value: 'hedge_profile', label: 'sales_management.hedge_profile_volume' },
            ]}
            readOnly={readOnly}
            defaultValue="full"
          />
        )}
      </div>
    </div>
  );
};
