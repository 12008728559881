import React, { useDeferredValue, useEffect, useMemo } from 'react';
import { Section, sectionStyles } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { validateSiret } from '@utils/validators/siret';
import classNames from 'classnames';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { BooleanInput } from '../../components/Inputs/Boolean';
import { ListInput } from '../../components/Inputs/List';
import { TextInput } from '../../components/Inputs/Text';
import { SectionHeader } from '../../components/SectionHeader';

export const InvoiceSettings = ({
  title,
  readOnly,
  queries: { edfAgencies, cegedimCredentials, edfOaCredentials },
  globals: { buyer, installation },
  form: { control, setValue, watch },
}: ContractFormSectionProps) => {
  const [contractType, calcBase] = watch(['type', 'calc_base']);
  const edfAgencyOptions: Option<number>[] =
    edfAgencies.data?.map((agency) => ({
      label: agency.name,
      value: agency.id,
    })) || [];

  const invoicingPlatform = watch('invoicing_platform');
  const credentials = invoicingPlatform === 'edf_oa' ? edfOaCredentials : cegedimCredentials;

  const oldBuyer = useDeferredValue(buyer);

  useEffect(() => {
    if (oldBuyer !== buyer) {
      setValue('credential_id', null);
    }
  }, [buyer, oldBuyer, setValue]);

  const credentialsOptions = useMemo(
    () =>
      credentials.data?.map((c) => ({
        label: c.username,
        value: c.id,
      })) || [],
    [credentials.data],
  );

  // Side-effect: after loading the list of credentials, if there's only one, set it.
  useEffect(() => {
    if (!watch('credential_id') && credentialsOptions?.length === 1) {
      setValue('credential_id', credentialsOptions[0].value);
    }
  }, [credentialsOptions, setValue, watch]);

  useEffect(() => {
    if (installation?.siret) setValue('_non_contract_props.installation.siret', installation.siret);
  }, [installation?.siret, setValue]);

  const indexedOa = contractType === 'ContractOa' && calcBase === 'index';

  const credentialText = indexedOa
    ? {
        label: 'sales_management.edf_oa_credential_label',
        tooltip: 'sales_management.edf_oa_credential_message',
      }
    : {
        label: 'sales_management.cegedim_credential_label',
        tooltip: null,
      };

  const indexInvoiceSettings = (
    <div className={sectionStyles.row}>
      <ListInput
        id="CredentialIdPicker"
        name="credential_id"
        options={credentialsOptions}
        control={control}
        readOnly={readOnly}
        isLoading={credentials.isPending}
        label={credentialText.label}
        tooltip={credentialText.tooltip}
        isDisabled={invoicingPlatform !== 'cegedim' || credentials.isError || credentialsOptions.length === 0}
        classNamePrefix="credential-picker"
        menuPlacement="top"
      />
    </div>
  );

  const loadCurveInvoiceSettings = (
    <>
      <div className={sectionStyles.row}>
        <ListInput
          id="EdfAgencyIdPicker"
          name="edf_agency_id"
          options={edfAgencyOptions}
          control={control}
          readOnly={readOnly}
          isLoading={edfAgencies.isPending}
          isDisabled={edfAgencies.isError}
          label="sales_management.edf_agency_id"
          classNamePrefix="edf-agency-picker"
          menuPlacement="top"
        />
      </div>

      <div className={classNames(sectionStyles['section-footer'], 'pt-4')}>
        {
          // We want to show Cegedim toggle even if there is no cegedim credentials to let clients know this option
          <div className={sectionStyles.row}>
            <BooleanInput
              id="ToggleCegedim"
              name="invoicing_platform"
              control={control}
              disabled={cegedimCredentials.data?.length === 0}
              tooltip={cegedimCredentials.data?.length === 0 ? 'sales_management.no_cegedim_credential_exists' : null}
              label="sales_management.sy"
              data-cy="send-to-cegedim-toggle"
              readOnly={readOnly}
            />
          </div>
        }
        <div className={sectionStyles.row}>
          <ListInput
            id="CredentialIdPicker"
            name="credential_id"
            options={credentialsOptions}
            control={control}
            readOnly={readOnly}
            isLoading={credentials.isPending}
            label={credentialText.label}
            tooltip={credentialText.tooltip}
            isDisabled={invoicingPlatform !== 'cegedim' || credentials.isError || credentialsOptions.length === 0}
            classNamePrefix="credential-picker"
            menuPlacement="top"
          />
          {installation?.country === 'FR' && (
            <TextInput
              id="InstallationSiret"
              name="_non_contract_props.installation.siret"
              control={control}
              readOnly={readOnly}
              rules={{
                validate: (siret: string) => {
                  if (invoicingPlatform !== 'cegedim' || !siret) return true;

                  return validateSiret(siret, installation?.owner_company_number);
                },
              }}
              disabled={invoicingPlatform !== 'cegedim'}
              label="sales_management.installation_siret"
              data-cy="installation-siret"
              helpBox={{ title: 'sales_management.installation_siret', text: 'sales_management.cegedim_siret_info' }}
              defaultValue={installation?.siret || ''}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={<Section className="p-3">{calcBase === 'index' ? indexInvoiceSettings : loadCurveInvoiceSettings}</Section>}
    />
  );
};
